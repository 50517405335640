import { Component, Host, h, Prop, Event, EventEmitter } from '@stencil/core';
import { PTZHeaderMenuBannerTypes } from './types/ptz-header-menu-banner.types';

@Component({
  tag: 'ptz-header-menu-banner',
  styleUrl: 'ptz-header-menu-banner.scss',
  shadow: false,
})

export class PTZHeaderMenuBanner {
  /**  Label do Banner */
  @Prop() label?: string;

  /** Tamanho do Banner */
  @Prop() size: PTZHeaderMenuBannerTypes.Size;

  /** Url do Banner */
  @Prop() href?: string

  /** Em qual aba abrir o link */
  @Prop() target?: PTZHeaderMenuBannerTypes.Target

  /** Evento que dispara ao clicar no banner */
  @Event({
    eventName: 'menuBannerClick',
    composed: true,
    cancelable: true,
    bubbles: true,
  }) menuBannerClick: EventEmitter<string | null>;

  render() {
    return (
      <Host>
        <a
          href={this.href}
          target={this.target ? this.target : '_self'}
          onClick={() => this.menuBannerClick.emit(this.label || null)}
          class={`ptz-header-menu-banner ptz-header-menu-banner-${this.size}`}
        >
          <div class={`ptz-header-menu-banner-card ptz-header-menu-banner-card-${this.size}`}>
            <slot />
          </div>
          {this.label ? <span>{this.label}</span> : null}
        </a>
      </Host>
    )
  }
}
