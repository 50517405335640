ptz-header-menu-drawer-item {

    ptz-icon,
    ptz-badge {
        display: flex;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    .ptz-header-menu-drawer-item {
        &-container {
            display: flex;
            color: inherit;
            align-items: center;
            justify-content: space-between;
            padding-left: $petz-spacing-md;
            padding-right: $petz-spacing-sm;
        }

        &-label {
            padding: $petz-spacing-xs 0;
            display: flex;
            align-items: center;
            gap: $petz-spacing-2xs;
        }     
        
        &-subcategories {
            cursor: pointer;
        }
    }
}