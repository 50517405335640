ptz-header-menu-banner {
  a {
    background: none;
    border: none;
    padding: 0;
    outline: inherit;
  }

  .ptz-header-menu-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: $petz-spacing-2xs;
    text-align: center;
    font-weight: $petz-font-weight-bold;
    font-size: $petz-spacing-sm;
    line-height: $petz-font-lineheight-lg;
    color: $petz-color-neutral-dark;
    cursor: pointer;
    text-decoration: none;

    span {
      text-decoration: none !important;
    }

    &-sm {
      min-width: 12.3125rem;
      min-height: 10.0625rem;
    }

    &-lg {
      min-width: 26.1875rem;
      min-height: 13.0625rem;
    }

    &-card {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      &-sm {
        height: 8.25rem;
      }

      &-lg {
        height: 100%;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        border-radius: $petz-rounded-md;
      }
    }
  }
}

@media screen and (max-width: $petz-breakpoint-md) {
  ptz-header-menu-banner {
    .ptz-header-menu-banner {
      &-sm {
        min-width: 8.125rem;
        min-height: 8.0625rem;
      }

      &-lg {
        min-width: 17.75rem;
        min-height: 8.875rem;
      }

      &-card {
        &-sm {
          height: 6.25rem;
        }
      }
    }
  }
}
